.SidebarConstructs {
    background-color: #fff;

	.options {
		.SidebarOptionsConstructs {
			border-bottom: 1px solid @input-border-color;

			&:first-child {
			border-top: 1px solid @input-border-color;
			}
		}
	}

	.add-construct {
		padding: 23px 14px;
		button {
			padding: 0;
		}
	}

	.add-selected-constructs {
		padding: 10px;
		display: flex;

		> button {
			flex: 1;
		}
	}
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;