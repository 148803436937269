.segments-breakdown-modal{
	.loading-container{

		& > *{
			max-height: 471px;
		}
	}
	

	.title{
		display: flex;
		gap: 5px;
		align-items: center;
		width: 90%;
		padding-right: 10px;
		text-align: center;


		& > *{
			font-size: 15px;
			flex-basis: 0;
			flex-grow: 2;
		}

		.swap-icon{
			color: #1991EB;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-grow: 1;
		}
	}

	
	.total-count-container {
		display: flex;
		align-items: center;
		padding-left: 22px;
		background-color: #F0F0F0;
		min-height: 44px;
		font-weight: bold;
		font-size: 16px;
		margin-bottom: 10px;
	}

	.count-number{
		background-color: #1890FF;
		min-width: 31px;
		border-radius: 35px;
		padding: 1px 4px;
		font-size: 14px;
		margin-left: 5px;
		height: 20px;
		font-weight: normal;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}

	.ant-modal-body{
		padding:  0;
	}

	.segments-breakdown-tree-container {
		height: 350px;
		overflow-y: auto;

		.ant-tree-node-selected{
			background: inherit;
		}

		.segments-breakdown-tree{
			padding:0 10px;

			.ant-tree-node-content-wrapper{
				width: 100%;

				.ant-tree-title{
					display: flex;
					align-items: center;

				}

				.node-title {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 0;
					width: 100%;
				}
			}

			.count-number{
				min-width: 27px;
				height: 16px;
			}
	
			.node {
				width: 100%;
				min-height: 24px;
				padding-right: 5px;
				padding-top: 3px;
			}
			
	
			.segment-node{
				font-weight: bold;
				background-color: #F0F0F0;
				margin-bottom: 10px;
			}
	
			.type-node{
				padding: 0;
				background-color: #BAE7FF;
				margin-bottom: 8px;
				min-height: 24px;

				.ant-tree-indent{
					display: none;
				}

				& *:hover {
					background-color: #BAE7FF;
				}
			}

			.option-node{

				.ant-tree-indent{
					display: none;
				}

				.option-title-container{
					max-width: 200px;
					height: 100%;
					padding: 0;
					margin: 0;
					min-height: 20px;

					.node-title{
						display: inline;
					}
	
					.count-number{
						background-color: transparent;
						color: #000000D9;
						font-size: 14px;
						font-weight: 700;
						display: inline;
						margin-left: 0;
					}
				}

				

				
			}
		}
	}
}
@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;