.CollapsableProject {
	.small-title {
		font-size: 11px;
		font-weight: 600;
		color: #858b94;
		margin-bottom: 14px;
		text-transform: uppercase;
	}

	.collapsable-header {
		background-color: #ffffff;
		padding: 18px 16px;
		position: relative;
		display: list-item;
		list-style-type: none;
	}

	.arrow-toggle {
		position: absolute;
		top: 10px;
		right: 7px;
		cursor: pointer;
		color: #1991eb;
	}

	.collapsable-body {
		overflow: hidden;
		transition: max-height 1s ease-in;
		max-height: 500px;

		.collapsable-body-container {
			padding: 10px 16px;
			background-color: rgba(255, 255, 255, 0.4);
		}

		.small-title {
			margin-bottom: 8px;
		}

		&.active {
			max-height: 500px;
			transition: max-height 0.3s ease-in;
		}

		&.hidden {
			max-height: 0;
			padding: 0;
			transition: max-height 0.3s ease-out;
		}
	}

	.ant-progress-outer {
		width: 200px;
	}
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;