.header-container {
    width: 100%;
    height: 90px;
    background-color: #FFFFFF;
    margin-left: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 0 22px;

	.header-info {
		display: flex;

		>:first-child {
			border-right: 1px solid rgba(66,75,90,0.15);
			padding-right: 19px;
		}

		.display-space {
			padding-left: 19px;
			align-items: center;

			.info {
				display: flex;
				align-items: center;
				justify-content: center;

				>:first-child {
					padding-right: 10px;
				}
			}
		}
	}
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;