.ProjectAddConstructs {
	.ant-row {
		flex-flow: wrap !important;
	}
	.container {
		padding: 0px 12px 30px 12px;
		display: flex;
	}
	.constructs-container {
		width: 100%;
		margin-right: 10px;
	}
	.constructs-filter {
		background-color: #f3f3f3;
		padding: 10px;
	}
	.construct-types-select {
		width: 100%;
		padding: 8px 0px;
	}
	.constructs-list {
		background-color: white;
		padding: 10px;
	}
	.constructs-sort {
		padding: 15px 0px;
	}
	.constructs-sort-1 {
		margin-left: auto;
		margin-bottom: 5px;
	}
	.construct-row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;

		& .construct-key {
			flex: 1;
			margin: 5px 0px 10px 0px;
			padding: 5px 10px;
			background-color: #ebebeb;
			font-weight: bold;
		}

		& .construct-element {
			width: 33%;

			& .construct-elem {
				display: flex;
				margin: 2px;

				&:hover {
					background-color: #c0c0c0 !important;
					cursor: pointer;
				}
			}
		}
	}
	.construct-usage-container{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

		.construct-usage {
			background-color: #ebebeb;
			min-width: 25px;
			border-radius: 35px;
			padding: 1px 4px;
			margin-left: 5px;
			height: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	
	.construct-icon {
		padding: 4px 8px;
	}
	.sidebar {
		width: 450px;

		.sidebar-constructs {
			background-color: #F7F7F7;

			>.title-container {
				height: 50px;
				display: flex;
				align-items: center;
				padding-left: 10px;
			}
		}
	}
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;