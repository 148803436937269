.project-map {
	.white-background {
		background-color: white;
	}

	.map-container {
		min-height: 600px;
	}
}

.collapse-history {
	.list-item {
		display: flex;
		flex-direction: column;

		.new-construct-tag {
			margin-top: 5px;
			margin-bottom: 5px;
		}

		.merged-box {
			display: flex;
			width: 100%;

			.unmerge-button {
				margin-top: 5px;
				margin-bottom: 5px;
				background-color: #1991EB;
				width: 25px;
				height: 25px;
				display: flex;
				align-items: center;
				justify-content: center;

				.arrow {
					color: white;
					font-size: 20px;
				}
			}
		}

		.constructs-history {
			display: flex;
			width: 100%;
		}

		.author {
			display: flex;
			width: 100%;
		}
	}
}

.default-colors {
	.colors-box {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 500px;

		.constructs {
			display: flex;
			flex-direction: column;
			flex: 1;

			.construct-box {
				display: flex;
				flex-direction: row;

				.color-box {
					cursor: pointer;
					width: 22px;
					height: 22px;
					border-radius: 2px;
					margin-top: 5px;
					margin-bottom: 5px;
				}

				.tag-box {
					margin-left: 5px;
					margin-top: 5px;
					margin-bottom: 5px;
				}
			}
		}

		.color-palette {
			display: flex;
			flex-direction: column;
			flex: 1;
			align-items: center;
			justify-content: center;
		}
	}
}


@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;