.latest-constructs{
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 10px;
    .latest-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 10px 11px;
        width: 100%;
        height: fit-content;
        background-color:#FAFAFA;
        .ant-typography{
            color: #424B5A;
        }
        .select{
            color: #667590;
            &.country{
                width: 145px;
            }
            &.industry{
                width:247px
            }
            &.period{
                width: 125px;
            }
        }

    }
    .latest-content{
        padding: 11px 0 3px 9px;
        .content-row{
            display: flex;
            .content-tag{
                margin-bottom: 9px;
                margin-right: 10px;
            }
        }
    }
}
@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;