// GENERAL STYLES ONLY

textarea {
	resize: none;
}

.filters-container {
	background-color: #f7f7f7;
	padding: 18px;

	label {
		font-weight: 600;
	}
}

.flex {
	display: inline-flex;
	justify-content: flex-end;
}

.ant-table {
	.ant-table-thead {
		.ant-table-cell {
			&,
			span {
				font-weight: bold;
			}
		}
	}
}
.ant-input {
	border-radius: 4px;
}
.ant-table-tbody {
	> tr {
		> td {
			border-bottom: 1px solid #e8e8e8;
		}
	}
}

.ant-menu {
	box-shadow: 2px 0 10px 0 rgba(66, 75, 90, 0.1) !important;
	.ant-menu-item,
	.ant-menu-submenu-title {
		width: inherit;
		background-color: rgba(0, 0, 0, 0.02);
	}

	.ant-menu-submenu {
		.ant-menu-item {
			&:not(.ant-menu-item-selected) {
				width: inherit;
				background-color: rgba(0, 0, 0, 0.06);
			}
		}
	}
}

.ant-tag {
	padding: 1px 7px;
}

.ant-input-affix-wrapper {
	.ant-input-suffix {
		color: rgba(0, 0, 0, 0.25);
	}
}

.btn-group {
	display: flex;
}

.blueButton {
	color: #1991eb;
	border-color: #1991eb;
}

.noBackgroundButton {
	color: #1991eb;
	background-color: transparent;
	border: none;
	box-shadow: none;

	&:hover{
		background-color: transparent;
	}
}

.ant-select {
	.ant-select-arrow {
		color: #424b5a;
	}
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;