.page-header {
    background-color: #292627;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .page-header__left {
        > .logo {
            float: left;
            margin-left: -24px;
        }

        > .brand {
            margin-left: 15px;
            position: relative;
            float: left;

            .separator-brand {
                height: 24px;
                width: 1px;
                background-color: white;
                opacity: 0.3;
                position: absolute;
                left: 0;
                top: 20px;
            }

            .ant-typography {
                margin-left: 16px;
                color: #FFFFFF;
                font-family: "Roboto Condensed";
                font-size: 11.2px;
                letter-spacing: 4px;
                line-height: 13px;
                text-transform: uppercase;
            }
        }
    }

    .ant-menu {
        float: right;
        margin-right: -34px;
        background-color: transparent;
        color: #9B9B9B;
        border-bottom-style: none;

        .ant-menu-submenu::after {
            border-bottom-style: none;
        }

        .ant-menu-submenu:hover::after {
            border-bottom-style: none;
        }
    }
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;