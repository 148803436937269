body {
  margin: 0;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}


.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 6px!important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 6px!important;
}

.ant-table .ant-table-thead .ant-table-cell, .ant-table .ant-table-thead .ant-table-cell span {
  font-weight: 600!important;
  font-family: "Source Sans Pro";
  font-size: 14px;  
}

.ant-input-affix-wrapper {
  border-radius: 4px!important;
}