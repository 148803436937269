.collapsable-lexicon {
	.collapsable-header {
		background-color: #ffffff;
		height: 47px;
		position: relative;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 11px 0 15px;
		border-left: 7px solid;
		font-size: 12px;
		color: #464F5D;
		font-weight: 600;
		cursor: pointer;

		.text {
			text-transform: uppercase
		}

		.arrow-toggle {
			cursor: pointer;
			color: #1991EB;
			font-size: 14px;
		}
	}

	.collapsable-container {
		background-color: '#f7f7f7';
		padding: 15px;

		.ant-collapse-content{
			background-color: #ebebe9;
		}

		.title {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.ant-tabs-tab-active {
			background-color: lightblue;
		}

		.ant-tabs-content-holder {
			width: 100%;
		}

		.ant-tabs {
			.ant-tabs-nav {
				margin-bottom: 0;
			}

			.ant-tabs-content {
				height: 100%;
				overflow-y: auto;
			}

			.ant-tabs-tab {
				color: #424B5A;
				font-size: 12px;
				line-height: 22px;
				height: 40px;
				border: none !important;
				width: 100px;
				margin-left: 0;
				justify-content: center;
			}
		}
	}

	.excerpt-container {
		.loyalist-container {
			display: flex;
			align-items: center;
			padding-top: 15px;
			position: absolute;
			width: 100%;

			.star-icon {
				color: #FFD835;
				padding-top: 10px;
			}

			.loyalist-text {
				color: #1991EB;
				margin: 10px 0 0 10px;
				font-size: 14px;
				width: calc(100% - 400px);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.transcripts-tabs {
			display: flex;
			align-items: flex-end;
			padding-top: 15px;

			.tabs-icons {
				color: gray;
				padding-left: 3px;
		  	}

			.lexicon-transcript-container {
				border: solid 1px #D9D9D9;
				padding: 16px;
				background-color: white;
				font-size: 16px;
				text-align: justify;

				.text-annotator {
					max-width: 500px;
					line-height: 1.5;
					cursor: pointer !important
				}
			}
		}
	}
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;