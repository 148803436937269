.editorContainer {
	margin: 0 auto;
	background-color: white;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */

	g path{
		/* This style applied globally, allows no filters to be applied when visiting nodes on the map. I did not find a way to prevent this with what the G6 library provided. */
		filter: none !important;
	}
}

.editBox {
	position: relative;
	// width: fit-content;
	height: 600px;
	border: 1px solid #7f7f7f;
	box-sizing: border-box;
}

.inputEdit {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	border: none;
	outline: none;
	text-align: center;
	background: transparent;
	z-index: 9;
}

.inputEditHidden {
	display: none;
}

.canvasBox {
	width: 1380px;
	display: flex;
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;