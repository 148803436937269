.SidebarTranscripts {
	width: 100%;

	.ant-tabs-nav {
		margin-bottom: 0;
	}

	.tab-container{
		background-color: #fff;
		border-radius: 2px;
	}

	.options-container{
		display: flex;
		overflow: hidden;
		height: fit-content;

		.options {
			min-width: 100%;

			.SidebarOptionsLexicon {
				border-bottom: 1px solid @input-border-color;

				&:first-child {
					border-top: 1px solid @input-border-color;
				}
			}

			&.toggle{
				margin-left: -304px;
				height: 0;
			}

			transition: margin-left 0.2s ease-in-out ;
		}

		.contruct-details{
			min-width: 100%;
			height: 0;

			&.toggle{
				height: fit-content;
			}
		}
	}

	.add-construct {
		padding: 23px 14px;

		button {
			padding: 0;
		}
	}

	.details-container {
		padding: 19px 12px 30px;

		.remove-trash {
			margin-right: 5px;
		}
	}

	.ant-tabs-nav-list {
		flex: 1;

		.ant-tabs-tab {
			margin: 0;
			width: 100%;
			justify-content: center;
			height: 40px;

			.ant-tabs-tab-btn {
				font-size: 12px;
				font-weight: 600;
			}
		}
	}

	.ant-tabs-tab-active {
		border: none !important;
	}

	.text {
		margin-bottom: 1px;
		display: block;
	}

	.excerpt {
		margin-bottom: 30px;
		display: inline-block;
		background-color: #B2F1E8;
		width: fit-content;
	}

	.no-excerpt {
		color: red;
		margin-bottom: 30px;
		display: inline-block;
		width: fit-content;
	}

	.link-container {
		background-color: white;
		padding: 10px;
	}

	.linked-excerpts {
		display: block;
		border-bottom: solid 1px #e7e7e7;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}

	.link-construct {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;