.recent-projects {
    min-width: 363px;
    border-radius: 4px;
    overflow: auto;
    background-color: #fff;
    margin-right: 10px;
    height: 651px;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #d6dee1;
		border-radius: 10px;
		border: 2px solid transparent;
		background-clip: content-box;
	}

    .recent-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px 0;
        width: 100%;
        height: 50px;
        background-color:#FAFAFA;
        font-weight: 600;
    }

    .recent-list {
        width: 100%;
        height: 621px;
        padding: 0 10px 30px 8px;

        .list-container {
            padding-right: 12px;

            .project-name {
                font-size: 22px;
                line-height: 28px;
                font-weight: bold;
            }

            .project-client {
                font-size: 20px;
                line-height: 28px;
                font-weight: bold;
            }

            .ant-space-item {
                margin-bottom: 7px;

                &:last-of-type {
                    line-height: 18px;
                    margin-bottom: 0;
                }
            }

            .ant-space-item:nth-of-type(2) {
                margin-bottom: 6px;
            }

			.ant-progress-text {
				font-size: 14px;
			}
        }
    }
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;