
.construct-tags {
    box-sizing: border-box;
    margin: 0;
    padding-left: 9.5px;
    padding-right: 9.5px;
    border: 1px solid;
    border-radius: 2px;
    border-radius: 2px;
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    cursor: pointer;
    height: 22px;
    text-align: center;
    text-transform: capitalize;
    width: fit-content;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;