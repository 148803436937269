.collapsable-header {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 15px;
    color: #505D68;

    .collapsable-header-item {
        color: #505D68;
        display: inline;
        margin-left: 30px;

		&.number {
            margin-left: 0;
        }

        &.time {
            font-weight: 600;
            color: #505D68;
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 14px;
        }
    }
}

@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;