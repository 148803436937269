.SidebarOptionsLexicon {
	cursor: pointer;

	.sidebar-header {
		background-color: #ffffff;
		height: 47px;
		position: relative;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 11px 0 15px;
		border-left: 7px solid;
		font-size: 12px;
		color: #464F5D;
		font-weight: 600;

		.arrow-toggle {
			cursor: pointer;
			color: #2174C1;
			font-size: 14px;
			margin-right: 10px;
		}
	}

	.excerpt-component {
		padding: 10px 10px 10px 40px;
		border-bottom: solid 1px #ececeb;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.text {
		text-transform: uppercase;
	}

	.spinner {
		display: flex;
    	justify-content: center;
    	margin: 15px;
	}
}


@body-background: #ECECEB;@btn-border-radius-base: 0;@font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@input-border-color: #D9D9D9;@input-color: #424B5A;@input-placeholder-color: rgba(66,75,90,0.25);@layout-body-background: #ECECEB;@layout-trigger-background: #292627;@menu-bg: #F7F7F7;@menu-highlight-color: #0091FF;@menu-item-bg: #000000;@menu-item-boundary-margin: 0px;@menu-item-color: #424B5A;@menu-item-vertical-margin: 0px;@primary-color: #1991EB;@table-header-bg: #F7F7F7;